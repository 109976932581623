import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  TabContent,
  Table,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import useRestaurantServices from "../../services/restaurantService";
import { ToastContainer } from "react-toastify";
import { Plus } from "react-feather";
import CreateRestaurantModal from "./CreateRestaurantModal";
import DeleteRestaurantModal from "./DeleteRestaurantModal";
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';

import image from "../../../src/assets/developImg/default-image.jpg";

const GetAllRestaurants = () => {
  const [restaurantId, setRestaurantId] = useState(null);
  const {
    restaurantsData,
    fetchRestaurantInfo,
    restaurantInfo: hookRestaurantInfo,
    getRestaurants,
    success,
    isSubmitting
  } = useRestaurantServices();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [requestType, setRequestType] = useState("");
  const [restaurantData, setRestaurantData] = useState("");

  //Fetch data use
  const [lists, setList] = useState([]);

  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  //Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  //Search Variable
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredList, setFilteredLists] = useState([...lists]);

  //Update the render to use filteredLists
  const currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const applySpecialStyles = () => {
    const wrapperElement = document.getElementById("wrapper");

    if (wrapperElement) {
      if (showCreateForm || showDeleteForm) {
        wrapperElement.style.zIndex = "120";
        wrapperElement.style.position = "relative";
      } else {
        wrapperElement.style.zIndex = "1";
        wrapperElement.style.position = "";
      }
    }
  };

  useEffect(() => {
    applySpecialStyles();
  }, [showCreateForm], [showDeleteForm]);

  useEffect(() => {
    if (restaurantsData.length > 0) {
      setList(restaurantsData);
    }
  }, [restaurantsData, success]);

  useEffect(() => {
    if (searchTerm) {
      const filterItems = lists.filter(
        (item) =>
          item.restaurantName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.restaurantAddress
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      );
      setFilteredLists(filterItems);
    } else {
      setFilteredLists([...lists]);
    }
  }, [lists, searchTerm]);

  // Create new client
  const handleCreateClick = (id) => {
    id ? setRequestType("PUT") : setRequestType("POST");
    if (id) {
      fetchRestaurantInfo(id);
    }
    setShowCreateForm(true);
  };

  useEffect(() => {
    setRestaurantData(hookRestaurantInfo);
  }, [hookRestaurantInfo]);

  const handleCloseCreateForm = () => {
    setShowCreateForm(false);
    setRequestType("");
    setRestaurantData("");
  };

  const handleDeleteClick = (id) => {
    setRestaurantId(id);
    setRequestType("DELETE");
    setShowDeleteForm(true);
  };

  const handleCloseDeleteForm = () => {
    setRequestType("");
    setShowDeleteForm(false);
  };

  return (
    <>
      <Container>
        <TabContent>
          <Card
            className="card-border mb-0 h-100"
          >
            <Card.Header className="card-header-action">
              <h5> Restaurant Management </h5>
              <div className="card-action-wrap">
                <Button
                  variant="primary btn-animated"
                  size="sm"
                  className="ms-3"
                  id="addRestaurant"
                  restaurant=""
                  request={requestType}
                  onClick={() => handleCreateClick("")}
                >
                  <span>
                    <span className="icon">
                      <span className="feather-icon">
                        <Plus />
                      </span>
                    </span>
                    <span className="btn-text " size="lg">
                      Add new
                    </span>
                  </span>
                </Button>
              </div>
            </Card.Header>
            {restaurantsData.message === "No restaurants at the moment" ? (
              <div
                className="d-flex justify-content-center align-items-center text-center"
                style={{ height: "200px" }}
              >
                <p>No restaurants at the moment. Please create one.</p>
              </div>
            ) : (
              <Card.Body>
                <InputGroup className="mb-4">
                  <Form.Control
                    className="table-search"
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </InputGroup>
                {currentItems.length === 0 ? (
                  <div
                    className="d-flex justify-content-center align-items-center text-center"
                    style={{ height: "200px" }}
                  >
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      {"\u00A0\u00A0\u00A0"}Fetching restaurants data...
                    </>
                  </div>
                ) : (
                  <div className="invoice-list-view">
                    <div className="table-responsive">
                      <Table responsive striped hover className="mb-0">
                        <thead>
                          <tr>
                            <th>
                              <span className="d-flex">
                                <span className="flex-grow-1">
                                  Restaurant
                                </span>
                              </span>
                            </th>
                            <th>
                              <span className="d-flex">
                                <span className="flex-grow-1">
                                  Restaurant Name
                                </span>
                              </span>
                            </th>
                            <th>
                              <span className="d-flex">
                                <span className="flex-grow-1">
                                  Restaurant Address
                                </span>
                              </span>
                            </th>
                            <th>
                              <span className="d-flex">
                                <span className="flex-grow-1">
                                  Restaurant Contact
                                </span>
                              </span>
                            </th>
                            <th>
                              <span className="d-flex">
                                <span className="flex-grow-1">Action</span>
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody id="dataTable">
                          {currentItems.map((item) => (
                            <tr key={item._id}>
                              <td>
                                {item.restaurantLogo ? (
                                  <img
                                    src={`${item.restaurantLogo}?timestamp=${Date.now()}`}
                                    alt="logo"
                                    style={{
                                      width: '75px',
                                      height: '75px',
                                      objectFit: 'cover',
                                      borderRadius: '50%',
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={image}
                                    alt="default"
                                    style={{
                                      width: '75px',
                                      height: '75px',
                                      objectFit: 'cover',
                                    }}
                                  />
                                )}
                              </td>
                              <td>{item.restaurantName}</td>
                              <td>{item.restaurantAddress}</td>
                              <td>{item.restaurantContact ? `0${item.restaurantContact}` : ''}</td>
                              <td>
                                <Button
                                  size="sm"
                                  variant="soft-primary"
                                  className="btn-rounded"
                                  style={{ margin: 5 }}
                                  data-bs-toggle="tooltip"
                                  id="editRestaurant"
                                  onClick={() => handleCreateClick(item._id)}
                                >
                                  Edit
                                </Button>

                                <Button
                                  size="sm"
                                  variant="soft-danger"
                                  style={{ margin: 5 }}
                                  className="btn-rounded"
                                  id="deleteRestaurant"
                                  onClick={() => handleDeleteClick(item._id)}
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <Row>
                        <Col
                          md={{ span: 2, offset: 10 }}
                          xs={{ span: 12, offset: 0 }}
                          className="text-center mt-3"
                        >
                          <Button
                            variant="primary btn-animated"
                            className="btn-icon"
                            disabled={currentPage === 1}
                            onClick={() => paginate(currentPage - 1)}
                          >
                            <span>
                              <i className="ri-arrow-left-s-line" />
                            </span>
                          </Button>
                          <span className="mx-1">{currentPage}</span>
                          <Button
                            variant="primary btn-animated "
                            className="btn-icon"
                            disabled={indexOfLastItem >= lists.length}
                            onClick={() => paginate(currentPage + 1)}
                          >
                            <span>
                              <i className="ri-arrow-right-s-line" />
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </Card.Body>
            )}
          </Card>
        </TabContent>
      </Container>
      <ToastContainer
        position="top-right"
        style={{ top: "70px", right: "20px" }}
        enableMultiContainer
        containerId="toast-container"
      />
      <CreateRestaurantModal
        showForm={showCreateForm}
        handleCloseForm={handleCloseCreateForm}
        restaurant={restaurantData}
        getRestaurants = {getRestaurants}
        //restaurantId={restaurantId}
        request={requestType}
      />
      <DeleteRestaurantModal
        showForm={showDeleteForm}
        handleCloseForm={handleCloseDeleteForm}
        restaurantId={restaurantId}
        getRestaurants = {getRestaurants}
        request={requestType}
      />
    </>
  );
};

export default GetAllRestaurants;
