import React, { useState, useEffect } from "react";
import {
  Row,
  Container,
  Col,
  Button,
} from "react-bootstrap";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';

import AddReviews from './AddReviewsForm';

// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const ReviewDetails = ({ restaurantInfo }) => {
  const [reviews, setReviews] = useState([]);
  const [open, setOpen] = useState(false);
  const [newReview, setNewReview] = useState({ author_name: '', rating: 0, text: '' });

  useEffect(() => {
    setReviews(restaurantInfo.reviews);
  }, [restaurantInfo]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewReview({ ...newReview, [name]: value });
  };

  const handleRatingChange = (e, newValue) => {
    setNewReview({ ...newReview, rating: newValue });
  };

  const handleSubmit = async (e) =>{
    e.preventDefault();
    const timenow = Date.now() / 1000;
    const fromKonfem = true;
    const updatedReviews = [...reviews, { ...newReview, time: timenow, fromKonfem: fromKonfem }];
    if (updatedReviews) {
      const reviewsJson = JSON.stringify(updatedReviews);

      // Save to database the newly added review - with additional time and flag fromKonfem
      await fetch(`${backendUri}/api/v0/public/restaurants/updateReviews/${restaurantInfo._id}?updatedReviews=${encodeURIComponent(reviewsJson)}`)
    }
    setReviews(updatedReviews);
    setNewReview({ author_name: '', rating: 0, text: '' });
    handleClose();
  };

  const anonymizeName = (name) => {
    if (!name) {
      return '***';
    }
    if (name.length <= 2) {
      return name + "***";
    }
    return name.substring(0, 2) + '***';
  };

  return (
    <Container>
      <Button 
        className="btn btn-outline-primary btn-sm mb-3"
        onClick={handleOpen}
        style={{ fontSize: '0.9rem' }}
      >
        Drop us a Review
      </Button>
      <Row>
        <Col>
          <AddReviews
            open={open}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            newReview={newReview}
            handleChange={handleChange}
            handleRatingChange={handleRatingChange}
          />
          {reviews && reviews.length > 0 ? (
            <List>
              {reviews.map((review, index) => (
                <ListItem key={index} alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt={review.author_name} src="/static/images/avatar/1.jpg" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="h6"
                          color="textPrimary"
                        >
                          {anonymizeName(review.author_name)}
                        </Typography>
                        <Rating value={review.rating} readOnly />
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          color="textSecondary"
                        >
                          {review.text}
                        </Typography>
                        <br />
                        <Typography
                          component="span"
                          variant="caption"
                          color="textSecondary"
                        >
                          {new Date(review.time * 1000).toLocaleDateString()}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="body1">No reviews yet</Typography>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ReviewDetails;
