import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { toggleCollapsedNav } from "../../../redux/action/Theme";
import { useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

import ProfileIntro from "./ProfileIntro";
import Header from "./Header";
import Body from "./Body";
import useRestaurantServices from "../../../services/restaurantService";
import useRestaurantPackageServices from "../../../services/restaurantPackageService";
import useRestaurantMenuServices from "../../../services/restaurantMenuService";
import useClientServices from "../../../services/clientService";
import { useAuth } from "../../../services/authContext";


const RestaurantsProfile = ({ toggleCollapsedNav }) => {
  const { user } = useAuth();
  // Tab management
  const [activeTab, setActiveTab] = useState("Menus");

  const { id } = useParams();
  const { fetchRestaurantInfo, restaurantInfo: hookRestaurantInfo } = useRestaurantServices();
  const {
    getRestaurantPackages,
    restaurantsPackageData,
  } = useRestaurantPackageServices();

  const { getRestaurantMenus, restaurantsMenuData } = useRestaurantMenuServices();

  useEffect(() => {
    toggleCollapsedNav(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRestaurantPackages(id);
    getRestaurantMenus(id);
    fetchRestaurantInfo(id);
  }, []);

  const switchTab = (tabLink) => {
    setActiveTab(tabLink);
  };

  const [token, setToken] = useState(Cookies.get("token"));
  const [decodedToken, setDecodedToken] = useState(null);
  const { fetchClientInfo, clientInfo } = useClientServices();

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      setDecodedToken(decoded);
    }
  }, [token]);

  useEffect(() => {
    if (decodedToken) {
      if (user.role === "client") {
        fetchClientInfo(decodedToken.userId);
      }
    }
  }, [decodedToken, fetchClientInfo]);

  return (
    <div className="hk-pg-body font-monospace">
      <Container>
        <div className="profile-wrap" style={{ marginTop: '100px', fontSize: '0.8rem' }}>
          <ProfileIntro
            restaurantInfo={hookRestaurantInfo}
            activeTab={activeTab}
            clientInfo={clientInfo}
          />
          <Header switchTab={switchTab} activeTab={activeTab} />
          <Body
            activeTab={activeTab}
            restaurantId = {id}
            restaurantContactNumber = {hookRestaurantInfo.restaurantContact}
            restaurantsPackageData={restaurantsPackageData}
            restaurantsMenuData={restaurantsMenuData}
            restaurantInfo={hookRestaurantInfo}
          />
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed } = theme;
  return { navCollapsed };
};

export default connect(mapStateToProps, { toggleCollapsedNav })(
  RestaurantsProfile
);
