import React, { useEffect, useState } from "react";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";
import { Button, Col, Form, Row, InputGroup, Spinner } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import * as Icons from "react-feather";
import InputMask from "react-input-mask";
import { ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils, faMapMarkerAlt, faCity, faEnvelope, faGlobe, faMap, faLink, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { Country, State } from "country-state-city";
import moment from "moment";

import defaultImage from "../../assets/dist/img/default_photo.png";
import useRestaurantServices from "../../services/restaurantService";

const RestaurantInfo = () => {
  const [countries, setAllCountries] = useState([]);
  const [states, setAllStates] = useState([]);
  const [restaurantName, setRestaurantName] = useState("");
  const [restaurantPlacesId, setRestaurantPlacesId] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [restaurantContact, setRestaurantContact] = useState("");
  const [paymentDetails, setPaymentDetails] = useState("");
  const [refundPolicy, setRefundPolicy] = useState("");
  const [tags, setTags] = useState("");
  const [description, setDescription] = useState("");
  const [addressLink, setAddressLink] = useState("");
  const [isBoarded, setIsBoarded] = useState("");
  const [ratings, setRatings] = useState("");
  const [reviews, setReviews] = useState([]);
  const [photoReferences, setPhotoReferences] = useState([]);
  const [photoFiles, setPhotoFiles] = useState([]);
  const [restaurantPromo, setRestaurantPromo] = useState("");
  const [sun, setSun] = useState(0);
  const [mon, setMon] = useState(1);
  const [tue, setTue] = useState(2);
  const [wed, setWed] = useState(3);
  const [thu, setThu] = useState(4);
  const [fri, setFri] = useState(5);
  const [sat, setSat] = useState(6);
  const [sunStart, setSunStart] = useState(new Date());
  const [sunEnd, setSunEnd] = useState(new Date());
  const [isSun2TimeSet, setSun2Time] = useState(false);
  const [sunStart2, setSunStart2] = useState(new Date());
  const [sunEnd2, setSunEnd2] = useState(new Date());
  const [monStart, setMonStart] = useState(new Date());
  const [monEnd, setMonEnd] = useState(new Date());
  const [isMon2TimeSet, setMon2Time] = useState(false);
  const [monStart2, setMonStart2] = useState(new Date());
  const [monEnd2, setMonEnd2] = useState(new Date());
  const [tueStart, setTueStart] = useState(new Date());
  const [tueEnd, setTueEnd] = useState(new Date());
  const [isTue2TimeSet, setTue2Time] = useState(false);
  const [tueStart2, setTueStart2] = useState(new Date());
  const [tueEnd2, setTueEnd2] = useState(new Date());
  const [wedStart, setWedStart] = useState(new Date());
  const [wedEnd, setWedEnd] = useState(new Date());
  const [isWed2TimeSet, setWed2Time] = useState(false);
  const [wedStart2, setWedStart2] = useState(new Date());
  const [wedEnd2, setWedEnd2] = useState(new Date());
  const [thuStart, setThuStart] = useState(new Date());
  const [thuEnd, setThuEnd] = useState(new Date());
  const [isThu2TimeSet, setThu2Time] = useState(false);
  const [thuStart2, setThuStart2] = useState(new Date());
  const [thuEnd2, setThuEnd2] = useState(new Date());
  const [friStart, setFriStart] = useState(new Date());
  const [friEnd, setFriEnd] = useState(new Date());
  const [isFri2TimeSet, setFri2Time] = useState(false);
  const [friStart2, setFriStart2] = useState(new Date());
  const [friEnd2, setFriEnd2] = useState(new Date());
  const [satStart, setSatStart] = useState(new Date());
  const [satEnd, setSatEnd] = useState(new Date());
  const [isSat2TimeSet, setSat2Time] = useState(false);
  const [satStart2, setSatStart2] = useState(new Date());
  const [satEnd2, setSatEnd2] = useState(new Date());
  const [isRestDaySun, setIsRestDaySun] = useState(0);
  const [isRestDayMon, setIsRestDayMon] = useState(0);
  const [isRestDayTue, setIsRestDayTue] = useState(0);
  const [isRestDayWed, setIsRestDayWed] = useState(0);
  const [isRestDayThu, setIsRestDayThu] = useState(0);
  const [isRestDayFri, setIsRestDayFri] = useState(0);
  const [isRestDaySat, setIsRestDaySat] = useState(0);
  const [restaurantLogo, setRestaurantLogo] = useState(null);
  const [gotFile, setGotFile] = useState(false);
  const [fileName, setFileName] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);
  const [restaurantId, setRestaurantId] = useState("");
  const requestType = "PUT";
  const {
    manageRestaurant,
    success,
    clearSuccess,
    isSubmitting,
    clearIsSubmitting,
    restaurantInfo,
    fetchRestaurantInfo,
  } = useRestaurantServices();

  useEffect(() => {
    fetchRestaurantInfo();
  }, []);

  useEffect(() => {
    setRestaurantName(restaurantInfo.restaurantName);
    setRestaurantPlacesId(restaurantInfo.restaurantPlacesId);
    setAddressLine1(restaurantInfo.addressLine1);
    setAddressLine2(restaurantInfo.addressLine2);
    setCity(restaurantInfo.city);
    setState(restaurantInfo.state);
    setZipCode(restaurantInfo.zipCode);
    setCountry(restaurantInfo.country);
    setRestaurantContact(restaurantInfo.restaurantContact);
    setAddressLink(restaurantInfo.addressLink);
    setFileName(restaurantInfo.restaurantLogo);
    setRestaurantId(restaurantInfo._id);
    setIsBoarded(restaurantInfo.isBoarded);
    setPaymentDetails(restaurantInfo.paymentDetails);
    setRefundPolicy(restaurantInfo.refundPolicy);
    setTags(restaurantInfo.tags);
    setDescription(restaurantInfo.description);
    setRatings(restaurantInfo.ratings);
    setReviews(restaurantInfo.reviews);
    setPhotoReferences(restaurantInfo.photoReferences);
    setPhotoFiles(restaurantInfo.photoFiles);
    setRestaurantPromo(restaurantInfo.restaurantPromo);

    const sunBusinessHours = restaurantInfo.businessHours?.find(businessHour => businessHour.day === 0);
    if (sunBusinessHours) {
      setSunStart(sunBusinessHours.startTime);
      setSunEnd(sunBusinessHours.endTime);
      setSunStart2(sunBusinessHours.startTime2);
      setSunEnd2(sunBusinessHours.endTime2);
      setIsRestDaySun(sunBusinessHours.isRestDay);
      setSun2Time(sunBusinessHours.startTime2 !== null && sunBusinessHours.endTime2 !== null && sunBusinessHours.setTime2);
    } else {
      setSunStart(new Date("Mon Jan 1 2024 10:00:00 GMT+0800 (Malaysia Time)"));
      setSunEnd(new Date("Mon Jan 1 2024 22:00:00 GMT+0800 (Malaysia Time)"));
      setSunStart2(new Date("Mon Jan 1 2024 17:00:00 GMT+0800 (Malaysia Time)"));
      setSunEnd2(new Date("Mon Jan 1 2024 22:00:00 GMT+0800 (Malaysia Time)"));
      setSun2Time(false);
    }
    const monBusinessHours = restaurantInfo.businessHours?.find(businessHour => businessHour.day === 1);
    if (monBusinessHours) {
      setMonStart(monBusinessHours.startTime);
      setMonEnd(monBusinessHours.endTime);
      setMonStart2(monBusinessHours.startTime2);
      setMonEnd2(monBusinessHours.endTime2);
      setIsRestDayMon(monBusinessHours.isRestDay);
      setMon2Time(monBusinessHours.startTime2 !== null && monBusinessHours.endTime2 !== null && monBusinessHours.setTime2);
    } else {
      setMonStart(new Date("Mon Jan 1 2024 10:00:00 GMT+0800 (Malaysia Time)"));
      setMonEnd(new Date("Mon Jan 1 2024 22:00:00 GMT+0800 (Malaysia Time)"));
      setMonStart2(new Date("Mon Jan 1 2024 17:00:00 GMT+0800 (Malaysia Time)"));
      setMonEnd2(new Date("Mon Jan 1 2024 22:00:00 GMT+0800 (Malaysia Time)"));
      setMon2Time(false);
    }
    const tueBusinessHours = restaurantInfo.businessHours?.find(businessHour => businessHour.day === 2);
    if (tueBusinessHours) {
      setTueStart(tueBusinessHours.startTime);
      setTueEnd(tueBusinessHours.endTime);
      setTueStart2(tueBusinessHours.startTime2);
      setTueEnd2(tueBusinessHours.endTime2);
      setIsRestDayTue(tueBusinessHours.isRestDay);
      setTue2Time(tueBusinessHours.startTime2 !== null && tueBusinessHours.endTime2 !== null && tueBusinessHours.setTime2);
    } else {
      setTueStart(new Date("Mon Jan 1 2024 10:00:00 GMT+0800 (Malaysia Time)"));
      setTueEnd(new Date("Mon Jan 1 2024 22:00:00 GMT+0800 (Malaysia Time)"));
      setTueStart2(new Date("Mon Jan 1 2024 17:00:00 GMT+0800 (Malaysia Time)"));
      setTueEnd2(new Date("Mon Jan 1 2024 22:00:00 GMT+0800 (Malaysia Time)"));
      setTue2Time(false);
    }
    const wedBusinessHours = restaurantInfo.businessHours?.find(businessHour => businessHour.day === 3);
    if (wedBusinessHours) {
      setWedStart(wedBusinessHours.startTime);
      setWedEnd(wedBusinessHours.endTime);
      setWedStart2(wedBusinessHours.startTime2);
      setWedEnd2(wedBusinessHours.endTime2);
      setIsRestDayWed(wedBusinessHours.isRestDay);
      setWed2Time(wedBusinessHours.startTime2 !== null && wedBusinessHours.endTime2 !== null && wedBusinessHours.setTime2);
    } else {
      setWedStart(new Date("Mon Jan 1 2024 10:00:00 GMT+0800 (Malaysia Time)"));
      setWedEnd(new Date("Mon Jan 1 2024 22:00:00 GMT+0800 (Malaysia Time)"));
      setWedStart2(new Date("Mon Jan 1 2024 17:00:00 GMT+0800 (Malaysia Time)"));
      setWedEnd2(new Date("Mon Jan 1 2024 22:00:00 GMT+0800 (Malaysia Time)"));
      setWed2Time(false);
    }
    const thuBusinessHours = restaurantInfo.businessHours?.find(businessHour => businessHour.day === 4);
    if (thuBusinessHours) {
      setThuStart(thuBusinessHours.startTime);
      setThuEnd(thuBusinessHours.endTime);
      setThuStart2(thuBusinessHours.startTime2);
      setThuEnd2(thuBusinessHours.endTime2);
      setIsRestDayThu(thuBusinessHours.isRestDay);
      setThu2Time(thuBusinessHours.startTime2 !== null && thuBusinessHours.endTime2 !== null && thuBusinessHours.setTime2);
    } else {
      setThuStart(new Date("Mon Jan 1 2024 10:00:00 GMT+0800 (Malaysia Time)"));
      setThuEnd(new Date("Mon Jan 1 2024 22:00:00 GMT+0800 (Malaysia Time)"));
      setThuStart2(new Date("Mon Jan 1 2024 17:00:00 GMT+0800 (Malaysia Time)"));
      setThuEnd2(new Date("Mon Jan 1 2024 22:00:00 GMT+0800 (Malaysia Time)"));
      setThu2Time(false);
    }
    const friBusinessHours = restaurantInfo.businessHours?.find(businessHour => businessHour.day === 5);
    if (friBusinessHours) {
      setFriStart(friBusinessHours.startTime);
      setFriEnd(friBusinessHours.endTime);
      setFriStart2(friBusinessHours.startTime2);
      setFriEnd2(friBusinessHours.endTime2);
      setIsRestDayFri(friBusinessHours.isRestDay);
      setFri2Time(friBusinessHours.startTime2 !== null && friBusinessHours.endTime2 !== null && friBusinessHours.setTime2);
    } else {
      setFriStart(new Date("Mon Jan 1 2024 10:00:00 GMT+0800 (Malaysia Time)"));
      setFriEnd(new Date("Mon Jan 1 2024 22:00:00 GMT+0800 (Malaysia Time)"));
      setFriStart2(new Date("Mon Jan 1 2024 17:00:00 GMT+0800 (Malaysia Time)"));
      setFriEnd2(new Date("Mon Jan 1 2024 22:00:00 GMT+0800 (Malaysia Time)"));
      setFri2Time(false);
    }
    const satBusinessHours = restaurantInfo.businessHours?.find(businessHour => businessHour.day === 6);
    if (satBusinessHours) {
      setSatStart(satBusinessHours.startTime);
      setSatEnd(satBusinessHours.endTime);
      setSatStart2(satBusinessHours.startTime2);
      setSatEnd2(satBusinessHours.endTime2);
      setIsRestDaySat(satBusinessHours.isRestDay);
      setSat2Time(satBusinessHours.startTime2 !== null && satBusinessHours.endTime2 !== null && satBusinessHours.setTime2);
    } else {
      setSatStart(new Date("Mon Jan 1 2024 10:00:00 GMT+0800 (Malaysia Time)"));
      setSatEnd(new Date("Mon Jan 1 2024 22:00:00 GMT+0800 (Malaysia Time)"));
      setSatStart2(new Date("Mon Jan 1 2024 17:00:00 GMT+0800 (Malaysia Time)"));
      setSatEnd2(new Date("Mon Jan 1 2024 22:00:00 GMT+0800 (Malaysia Time)"));
      setSat2Time(false);
    }
  }, [restaurantInfo]);

  const handlePhoneNo = (e) => {
    const inputPhoneNo = e.target.value;
    const cleanedPhoneNo = inputPhoneNo.replace(/[ -]/g, "");
    setRestaurantContact(cleanedPhoneNo);
  };

  // Function to handle file selection
  const handleFileSelect = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      setGotFile(true);
      setRestaurantLogo(file);
      setPreviewUrl(URL.createObjectURL(file)); // Set the preview URL
    } else {
      setGotFile(false);
      setPreviewUrl(null); // Clear preview URL if no file selected
    }
  };

  const hideCalender = (event, picker) => {
    picker.container.find(".calendar-table").hide();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const businessHours = [
      { day: sun, startTime: sunStart, endTime: sunEnd, startTime2: sunStart2, endTime2: sunEnd2, isRestDay: isRestDaySun, setTime2: isSun2TimeSet },
      { day: mon, startTime: monStart, endTime: monEnd, startTime2: monStart2, endTime2: monEnd2, isRestDay: isRestDayMon, setTime2: isMon2TimeSet },
      { day: tue, startTime: tueStart, endTime: tueEnd, startTime2: tueStart2, endTime2: tueEnd2, isRestDay: isRestDayTue, setTime2: isTue2TimeSet },
      { day: wed, startTime: wedStart, endTime: wedEnd, startTime2: wedStart2, endTime2: wedEnd2, isRestDay: isRestDayWed, setTime2: isWed2TimeSet },
      { day: thu, startTime: thuStart, endTime: thuEnd, startTime2: thuStart2, endTime2: thuEnd2, isRestDay: isRestDayThu, setTime2: isThu2TimeSet },
      { day: fri, startTime: friStart, endTime: friEnd, startTime2: friStart2, endTime2: friEnd2, isRestDay: isRestDayFri, setTime2: isFri2TimeSet },
      { day: sat, startTime: satStart, endTime: satEnd, startTime2: satStart2, endTime2: satEnd2, isRestDay: isRestDaySat, setTime2: isSat2TimeSet },
    ];

    const submitData = {
      restaurantId,
      restaurantPlacesId,
      restaurantName,
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
      country,
      restaurantContact,
      paymentDetails,
      refundPolicy,
      tags,
      description,
      addressLink,
      isBoarded,
      ratings,
      reviews,
      photoReferences,
      photoFiles,
      restaurantPromo,
      gotFile,
      restaurantLogo,
      requestType,
      businessHours
    };
    manageRestaurant(submitData);
  };

  useEffect(() => {
    const countries = Country.getAllCountries();
    setAllCountries(countries);
  }, []);

  useEffect(() => {
    if (country) {
      const statesForCountry = State.getStatesOfCountry(country);
      setAllStates(statesForCountry);
    }
  }, [country]);

  const handleCountryChange = (event) => {
    const newCountry = event.target.value;
    setCountry(newCountry);
  };

  useEffect(() => {
    clearIsSubmitting();
    clearSuccess();
  }, [success]);

  const handleStateChange = (event) => {
    const newState = event.target.value;
    setState(newState);
  };

  // Custom description toolbar configuration
  const toolbarOptions = [
    [{ 'font': [] }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['link'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],
    ['clean'],
  ];

  const modules = {
    toolbar: toolbarOptions,
    clipboard: {
      matchVisual: false, // Disable Quill's default paste handling
    }
  };

  const maxCharacters = 1000;
  const [visibleChar, setVisibleChar] = useState(0);
  const handleDescriptionChange = (value) => {
    const stripHtmlTags = (html) => {
      const div = document.createElement('div');
      div.innerHTML = html;
      setVisibleChar((div.textContent || div.innerText || '').length);
    };
    stripHtmlTags(value);

    if (visibleChar <= maxCharacters) {
      setDescription(value);
    }
  };


  return (
    <>
      {restaurantInfo ? (
        <Form onSubmit={handleSubmit}>
          <Row className="gx-3">
            <Col sm={12}>
              <Form.Group>
                <div className="media align-items-center">
                  <div className="media-head me-5">
                    <div className="avatar avatar-rounded avatar-xxl">
                      {/* <img
                      src={
                        restaurantInfo.restaurantLogo
                          ? restaurantInfo.restaurantLogo
                          : defaultImage
                      }
                      alt="logo"
                      className="avatar-img"
                    /> */}
                      <img
                        src={
                          previewUrl
                            ? previewUrl
                            : restaurantInfo.restaurantLogo
                              ? restaurantInfo.restaurantLogo
                              : defaultImage
                        }
                        alt="logo"
                        className="avatar-img"
                      />
                    </div>
                  </div>
                  <div className="media-body">
                    <Button variant="soft-primary" className="btn-file mb-1">
                      Update Restaurant Logo
                      {/* <Form.Control
                      type="file"
                      accept="image/*"
                      name="restaurantLogo"
                      onChange={(e) => {
                        if (e.target.files.length > 0) {
                          setGotFile(true);
                          setRestaurantLogo(e.target.files[0]);
                        } else {
                          setGotFile(false);
                        }
                      }}
                    /> */}
                      <Form.Control
                        type="file"
                        accept="image/*"
                        name="restaurantLogo"
                        onChange={handleFileSelect}
                      />
                    </Button>
                  </div>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <div className="title title-xs title-wth-divider text-primary text-uppercase my-4">
            <span>Restaurant Info</span>
          </div>
          <Row className="gx-3">
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>Restaurant Name</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faUtensils} />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Enter Restaurant Name"
                    value={restaurantName}
                    onChange={(e) => {
                      setRestaurantName(e.target.value);
                    }}
                    required
                    id="restaurantName"
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-3">
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>Address Line 1</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Enter Address Line 1 "
                    value={addressLine1}
                    onChange={(e) => {
                      setAddressLine1(e.target.value);
                    }}
                    required
                    id="addressLine1"
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>Address Line 2</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Enter Address Line 2 "
                    value={addressLine2}
                    onChange={(e) => {
                      setAddressLine2(e.target.value);
                    }}
                    required
                    id="addressLine2"
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-3">
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label>City</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faCity} />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="City "
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                    required
                    id="city"
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label>Zip Code</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder="Enter Zip Code "
                    value={zipCode}
                    onChange={(e) => {
                      setZipCode(e.target.value);
                    }}
                    required
                    id="zipCode"
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-3">
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label>Country</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faGlobe} />
                  </InputGroup.Text>
                  <Form.Select
                    name="country"
                    onChange={handleCountryChange}
                    value={country}
                    required
                    id="country"
                  >
                    <option value="">Choose...</option>
                    {countries.map((country) => (
                      <option key={country.isoCode} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label>State</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faMap} />
                  </InputGroup.Text>
                  <Form.Select
                    name="state"
                    onChange={handleStateChange}
                    value={state}
                    required
                    id="state"
                  >
                    <option value="">Choose...</option>
                    {states.map((state) => (
                      <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-3">
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>Address Link</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faLink} />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Enter Address Link "
                    value={addressLink}
                    onChange={(e) => {
                      setAddressLink(e.target.value);
                    }}
                    id="addressLink"
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-3">
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>Restaurant Contact</Form.Label>
                <InputGroup>
                  <InputGroup.Text>+60</InputGroup.Text>
                  <InputMask
                    id="restaurantContact"
                    className="form-control"
                    name="adminContact"
                    mask="99-99999999999999999999999"
                    maskChar={null}
                    value={restaurantContact}
                    onChange={handlePhoneNo}
                    required
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-3">
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>Payment Details for Deposit collection (optional)</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faCreditCard} />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="AccountName: Bank Name: Account Number"
                    value={paymentDetails}
                    onChange={(e) => {
                      setPaymentDetails(e.target.value);
                    }}
                    id="paymentDetails"
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-3">
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>Refund Policy (Optional)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Your Refund Policy"
                  value={refundPolicy}
                  onChange={(e) => setRefundPolicy(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-3">
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>Relevant Tags/Labels for your restaurant (Optional - up to 6)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="halal, japanese, omakase, sushi"
                  value={tags}
                  onChange={(e) => setTags(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="gx-3">
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>Restaurant Description (Optional - max 1000 characters)</Form.Label>
                <ReactQuill
                  placeholder="Description"
                  value={description}
                  onChange={handleDescriptionChange}
                  modules={modules}
                />
                <div style={{ fontSize: '14px', color: '#6c757d', marginTop: '5px' }}>
                  {visibleChar}/{maxCharacters} characters
                </div>
              </Form.Group>
            </Col>
          </Row>
          <div className="title title-xs title-wth-divider text-primary text-uppercase my-4">
            <span>Business Hours</span>
          </div>
          <Row className="gx-3">
            <Form.Label><strong>Sunday</strong></Form.Label>
            <Col sm={5}>
              <Form.Group className="mb-3">
                <InputGroup>
                  <InputGroup.Text>
                    From
                  </InputGroup.Text>
                  <span className="input-affix-wrapper">
                    <span className="input-prefix">
                      <span className="feather-icon">
                        <Icons.Clock />
                      </span>
                    </span>
                    <DateRangePicker
                      initialSettings={{
                        singleDatePicker: true,
                        autoApply:true,
                        timePicker: true,
                        timePicker24Hour: false,
                        timePickerIncrement: 5,
                        locale: {
                          format: "hh:mm A",
                        },
                      }}
                      disabled={isRestDaySun}
                      onShow={hideCalender}
                      onApply={(event, picker) => {
                        const selectedTime = picker.startDate.toDate();
                        setSunStart(selectedTime);
                      }}
                      onHide={(event, picker) => {
                        const selectedTime = picker.startDate.toDate();
                        setSunStart(selectedTime);
                      }}
                    >
                      <Form.Control
                        className="input-timepicker"
                        type="text"
                        name="startTime"
                        onChange={() => {}}
                        disabled={isRestDaySun}
                        value={sunStart ? moment(sunStart).format("hh:mm A") : ''}
                        id="sunStart"
                      />
                    </DateRangePicker>
                  </span>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={5}>
              <Form.Group className="mb-3">
                <InputGroup>
                  <InputGroup.Text>
                    To
                  </InputGroup.Text>
                  <span className="input-affix-wrapper">
                    <span className="input-prefix">
                      <span className="feather-icon">
                        <Icons.Clock />
                      </span>
                    </span>
                    <DateRangePicker
                      initialSettings={{
                        singleDatePicker: true,
                        autoApply:true,
                        timePicker: true,
                        timePicker24Hour: false,
                        timePickerIncrement: 5,
                        locale: {
                          format: "hh:mm A",
                        },
                      }}
                      disabled={isRestDaySun}
                      onShow={hideCalender}
                      onApply={(event, picker) => {
                        const selectedTime = picker.endDate.toDate();
                        setSunEnd(selectedTime);
                      }}
                      onHide={(event, picker) => {
                        const selectedTime = picker.endDate.toDate();
                        setSunEnd(selectedTime);
                      }}
                    >
                      <Form.Control
                        className="input-timepicker"
                        type="text"
                        name="endTime"
                        onChange={() => {}}
                        disabled={isRestDaySun}
                        value={sunEnd ? moment(sunEnd).format("hh:mm A") : ''}
                        id="sunEnd"
                      />
                    </DateRangePicker>
                  </span>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={2} className="mt-1">
              <Form.Check
                type="checkbox"
                label="Rest Day"
                checked={isRestDaySun}
                onChange={(e) => setIsRestDaySun(e.target.checked ? 1 : 0)}
                id="restSun"
              />
            </Col>
          </Row>
          <input
            type="checkbox"
            style={{ transform: "scale(1.5)", marginRight: "10px" }}
            checked={isSun2TimeSet}
            onChange={(e) => {
              // If checkbox was previously checked and is now being unchecked
              if (isSun2TimeSet && !e.target.checked) {
                setSunStart2(null);
                setSunEnd2(null);
              }
              // Toggle checkbox state
              setSun2Time(!isSun2TimeSet);
            }}
          />
          <label> Tick to input another time range for Sunday</label>
          {!isSun2TimeSet && <><br /><br /></>}
          {isSun2TimeSet && (
            <Row className="gx-3">
              <Col sm={5}>
                <Form.Group className="mb-3">
                  <InputGroup>
                    <InputGroup.Text>
                      From
                    </InputGroup.Text>
                    <span className="input-affix-wrapper">
                      <span className="input-prefix">
                        <span className="feather-icon">
                          <Icons.Clock />
                        </span>
                      </span>
                      <DateRangePicker
                        initialSettings={{
                          singleDatePicker: true,
                          autoApply:true,
                          timePicker: true,
                          timePicker24Hour: false,
                          timePickerIncrement: 5,
                          locale: {
                            format: "hh:mm A",
                          },
                        }}
                        disabled={isRestDaySun}
                        onShow={hideCalender}
                        onApply={(event, picker) => {
                          const selectedTime = isSun2TimeSet? picker.startDate.toDate() : null;
                          setSunStart2(selectedTime);
                        }}
                        onHide={(event, picker) => {
                          const selectedTime = isSun2TimeSet? picker.startDate.toDate() : null;
                          setSunStart2(selectedTime);
                        }}
                      >
                        <Form.Control
                          className="input-timepicker"
                          type="text"
                          name="startTime2"
                          onChange={() => {}}
                          disabled={isRestDaySun}
                          value={sunStart2 ? moment(sunStart2).format("hh:mm A") : ''}
                          id="sunStart2"
                        />
                      </DateRangePicker>
                    </span>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col sm={5}>
                <Form.Group className="mb-3">
                  <InputGroup>
                    <InputGroup.Text>
                      To
                    </InputGroup.Text>
                    <span className="input-affix-wrapper">
                      <span className="input-prefix">
                        <span className="feather-icon">
                          <Icons.Clock />
                        </span>
                      </span>
                      <DateRangePicker
                        initialSettings={{
                          singleDatePicker: true,
                          autoApply:true,
                          timePicker: true,
                          timePicker24Hour: false,
                          timePickerIncrement: 5,
                          locale: {
                            format: "hh:mm A",
                          },
                        }}
                        disabled={isRestDaySun}
                        onShow={hideCalender}
                        onApply={(event, picker) => {
                          const selectedTime = isSun2TimeSet? picker.endDate.toDate() : null;
                          setSunEnd2(selectedTime);
                        }}
                        onHide={(event, picker) => {
                          const selectedTime = isSun2TimeSet? picker.endDate.toDate() : null;
                          setSunEnd2(selectedTime);
                        }}
                      >
                        <Form.Control
                          className="input-timepicker"
                          type="text"
                          name="endTime2"
                          onChange={() => {}}
                          disabled={isRestDaySun}
                          value={sunEnd2 ? moment(sunEnd2).format("hh:mm A") : ''}
                          id="sunEnd2"
                        />
                      </DateRangePicker>
                    </span>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          )}
          
          <Row className="gx-3">
            <Form.Label><strong>Monday</strong></Form.Label>
            <Col sm={5}>
              <Form.Group className="mb-3">
                <InputGroup>
                  <InputGroup.Text>
                    From
                  </InputGroup.Text>
                  <span className="input-affix-wrapper">
                    <span className="input-prefix">
                      <span className="feather-icon">
                        <Icons.Clock />
                      </span>
                    </span>
                    <DateRangePicker
                      initialSettings={{
                        singleDatePicker: true,
                        autoApply:true,
                        timePicker: true,
                        timePicker24Hour: false,
                        timePickerIncrement: 5,
                        locale: {
                          format: "hh:mm A",
                        },
                      }}
                      disabled={isRestDayMon}
                      onShow={hideCalender}
                      onApply={(event, picker) => {
                        const selectedTime = picker.startDate.toDate();
                        setMonStart(selectedTime);
                      }}
                      onHide={(event, picker) => {
                        const selectedTime = picker.startDate.toDate();
                        setMonStart(selectedTime);
                      }}
                    >
                      <Form.Control
                        className="input-timepicker"
                        type="text"
                        name="startTime"
                        onChange={() => {}}
                        disabled={isRestDayMon}
                        value={monStart ? moment(monStart).format("hh:mm A") : ''}
                        id="monStart"
                      />
                    </DateRangePicker>
                  </span>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={5}>
              <Form.Group className="mb-3">
                <InputGroup>
                  <InputGroup.Text>
                    To
                  </InputGroup.Text>
                  <span className="input-affix-wrapper">
                    <span className="input-prefix">
                      <span className="feather-icon">
                        <Icons.Clock />
                      </span>
                    </span>
                    <DateRangePicker
                      initialSettings={{
                        singleDatePicker: true,
                        autoApply:true,
                        timePicker: true,
                        timePicker24Hour: false,
                        timePickerIncrement: 5,
                        locale: {
                          format: "hh:mm A",
                        },
                      }}
                      disabled={isRestDayMon}
                      onShow={hideCalender}
                      onApply={(event, picker) => {
                        const selectedTime = picker.endDate.toDate();
                        setMonEnd(selectedTime);
                      }}
                      onHide={(event, picker) => {
                        const selectedTime = picker.endDate.toDate();
                        setMonEnd(selectedTime);
                      }}
                    >
                      <Form.Control
                        className="input-timepicker"
                        type="text"
                        name="endTime"
                        onChange={() => {}}
                        disabled={isRestDayMon}
                        value={monEnd ? moment(monEnd).format("hh:mm A") : ''}
                        id="monEnd"
                      />
                    </DateRangePicker>
                  </span>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={2} className="mt-1">
              <Form.Check
                type="checkbox"
                label="Rest Day"
                checked={isRestDayMon}
                onChange={(e) => setIsRestDayMon(e.target.checked ? 1 : 0)}
                id="restMon"
              />
            </Col>
          </Row>
          
          <input
            type="checkbox"
            style={{ transform: "scale(1.5)", marginRight: "10px" }}
            checked={isMon2TimeSet}
            onChange={(e) => {
              // If checkbox was previously checked and is now being unchecked
              if (isMon2TimeSet && !e.target.checked) {
                setMonStart2(null);
                setMonEnd2(null);
              }
              // Toggle checkbox state
              setMon2Time(!isMon2TimeSet);
            }}
          />
          <label> Tick to input another time range for Monday</label>
          {!isMon2TimeSet && <><br /><br /></>}
          {isMon2TimeSet && (
            <Row className="gx-3">
              <Col sm={5}>
                <Form.Group className="mb-3">
                  <InputGroup>
                    <InputGroup.Text>
                      From
                    </InputGroup.Text>
                    <span className="input-affix-wrapper">
                      <span className="input-prefix">
                        <span className="feather-icon">
                          <Icons.Clock />
                        </span>
                      </span>
                      <DateRangePicker
                        initialSettings={{
                          singleDatePicker: true,
                          autoApply:true,
                          timePicker: true,
                          timePicker24Hour: false,
                          timePickerIncrement: 5,
                          locale: {
                            format: "hh:mm A",
                          },
                        }}
                        disabled={isRestDayMon}
                        onShow={hideCalender}
                        onApply={(event, picker) => {
                          const selectedTime = isMon2TimeSet ? picker.startDate.toDate() : null;
                          setMonStart2(selectedTime);
                        }}
                        onHide={(event, picker) => {
                          const selectedTime = isMon2TimeSet ? picker.startDate.toDate() : null;
                          setMonStart2(selectedTime);
                        }}
                      >
                        <Form.Control
                          className="input-timepicker"
                          type="text"
                          name="startTime2"
                          onChange={() => {}}
                          disabled={isRestDayMon}
                          value={monStart2 ? moment(monStart2).format("hh:mm A") : ''}
                          id="monStart2"
                        />
                      </DateRangePicker>
                    </span>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col sm={5}>
                <Form.Group className="mb-3">
                  <InputGroup>
                    <InputGroup.Text>
                      To
                    </InputGroup.Text>
                    <span className="input-affix-wrapper">
                      <span className="input-prefix">
                        <span className="feather-icon">
                          <Icons.Clock />
                        </span>
                      </span>
                      <DateRangePicker
                        initialSettings={{
                          singleDatePicker: true,
                          autoApply:true,
                          timePicker: true,
                          timePicker24Hour: false,
                          timePickerIncrement: 5,
                          locale: {
                            format: "hh:mm A",
                          },
                        }}
                        disabled={isRestDayMon}
                        onShow={hideCalender}
                        onApply={(event, picker) => {
                          const selectedTime = isMon2TimeSet? picker.endDate.toDate() : null;
                          setMonEnd2(selectedTime);
                        }}
                        onHide={(event, picker) => {
                          const selectedTime = isMon2TimeSet? picker.endDate.toDate() : null;
                          setMonEnd2(selectedTime);
                        }}
                      >
                        <Form.Control
                          className="input-timepicker"
                          type="text"
                          name="endTime2"
                          onChange={() => {}}
                          disabled={isRestDayMon}
                          value={monEnd2 ? moment(monEnd2).format("hh:mm A") : ''}
                          id="monEnd2"
                        />
                      </DateRangePicker>
                    </span>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          )}

          <Row className="gx-3">
            <Form.Label><strong>Tuesday</strong></Form.Label>
            <Col sm={5}>
              <Form.Group className="mb-3">
                <InputGroup>
                  <InputGroup.Text>
                    From
                  </InputGroup.Text>
                  <span className="input-affix-wrapper">
                    <span className="input-prefix">
                      <span className="feather-icon">
                        <Icons.Clock />
                      </span>
                    </span>
                    <DateRangePicker
                      initialSettings={{
                        singleDatePicker: true,
                        autoApply:true,
                        timePicker: true,
                        timePicker24Hour: false,
                        timePickerIncrement: 5,
                        locale: {
                          format: "hh:mm A",
                        },
                      }}
                      disabled={isRestDayTue}
                      onShow={hideCalender}
                      onApply={(event, picker) => {
                        const selectedTime = picker.startDate.toDate();
                        setTueStart(selectedTime);
                      }}
                      onHide={(event, picker) => {
                        const selectedTime = picker.startDate.toDate();
                        setTueStart(selectedTime);
                      }}
                    >
                      <Form.Control
                        className="input-timepicker"
                        type="text"
                        name="startTime"
                        onChange={() => {}}
                        disabled={isRestDayTue}
                        value={tueStart ? moment(tueStart).format("hh:mm A") : ''}
                        id="tueStart"
                      />
                    </DateRangePicker>
                  </span>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={5}>
              <Form.Group className="mb-3">
                <InputGroup>
                  <InputGroup.Text>
                    To
                  </InputGroup.Text>
                  <span className="input-affix-wrapper">
                    <span className="input-prefix">
                      <span className="feather-icon">
                        <Icons.Clock />
                      </span>
                    </span>
                    <DateRangePicker
                      initialSettings={{
                        singleDatePicker: true,
                        autoApply:true,
                        timePicker: true,
                        timePicker24Hour: false,
                        timePickerIncrement: 5,
                        locale: {
                          format: "hh:mm A",
                        },
                      }}
                      disabled={isRestDayTue}
                      onShow={hideCalender}
                      onApply={(event, picker) => {
                        const selectedTime = picker.endDate.toDate();
                        setTueEnd(selectedTime);
                      }}
                      onHide={(event, picker) => {
                        const selectedTime = picker.endDate.toDate();
                        setTueEnd(selectedTime);
                      }}
                    >
                      <Form.Control
                        className="input-timepicker"
                        type="text"
                        name="endTime"
                        onChange={() => {}}
                        disabled={isRestDayTue}
                        value={tueEnd ? moment(tueEnd).format("hh:mm A") : ''}
                        id="tueEnd"
                      />
                    </DateRangePicker>
                  </span>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={2} className="mt-1">
              <Form.Check
                type="checkbox"
                label="Rest Day"
                checked={isRestDayTue}
                onChange={(e) => setIsRestDayTue(e.target.checked ? 1 : 0)}
                id="restTue"
              />
            </Col>
          </Row>

          <input
            type="checkbox"
            style={{ transform: "scale(1.5)", marginRight: "10px" }}
            checked={isTue2TimeSet}
            onChange={(e) => {
              // If checkbox was previously checked and is now being unchecked
              if (isTue2TimeSet && !e.target.checked) {
                setTueStart2(null);
                setTueEnd2(null);
              }
              // Toggle checkbox state
              setTue2Time(!isTue2TimeSet);
            }}
          />
          <label> Tick to input another time range for Tuesday</label>
          {!isTue2TimeSet && <><br /><br /></>}
          {isTue2TimeSet && (
            <Row className="gx-3">
              <Col sm={5}>
                <Form.Group className="mb-3">
                  <InputGroup>
                    <InputGroup.Text>
                      From
                    </InputGroup.Text>
                    <span className="input-affix-wrapper">
                      <span className="input-prefix">
                        <span className="feather-icon">
                          <Icons.Clock />
                        </span>
                      </span>
                      <DateRangePicker
                        initialSettings={{
                          singleDatePicker: true,
                          autoApply:true,
                          timePicker: true,
                          timePicker24Hour: false,
                          timePickerIncrement: 5,
                          locale: {
                            format: "hh:mm A",
                          },
                        }}
                        disabled={isRestDayTue}
                        onShow={hideCalender}
                        onApply={(event, picker) => {
                          const selectedTime = isTue2TimeSet? picker.startDate.toDate() : null;
                          setTueStart2(selectedTime);
                        }}
                        onHide={(event, picker) => {
                          const selectedTime = isTue2TimeSet? picker.startDate.toDate() : null;
                          setTueStart2(selectedTime);
                        }}
                      >
                        <Form.Control
                          className="input-timepicker"
                          type="text"
                          name="startTime2"
                          onChange={() => {}}
                          disabled={isRestDayTue}
                          value={tueStart2 ? moment(tueStart2).format("hh:mm A") : ''}
                          id="tueStart2"
                        />
                      </DateRangePicker>
                    </span>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col sm={5}>
                <Form.Group className="mb-3">
                  <InputGroup>
                    <InputGroup.Text>
                      To
                    </InputGroup.Text>
                    <span className="input-affix-wrapper">
                      <span className="input-prefix">
                        <span className="feather-icon">
                          <Icons.Clock />
                        </span>
                      </span>
                      <DateRangePicker
                        initialSettings={{
                          singleDatePicker: true,
                          autoApply:true,
                          timePicker: true,
                          timePicker24Hour: false,
                          timePickerIncrement: 5,
                          locale: {
                            format: "hh:mm A",
                          },
                        }}
                        disabled={!isTue2TimeSet || isRestDayTue}
                        onShow={hideCalender}
                        onApply={(event, picker) => {
                          const selectedTime = isTue2TimeSet? picker.endDate.toDate() : null;
                          setTueEnd2(selectedTime);
                        }}
                        onHide={(event, picker) => {
                          const selectedTime = isTue2TimeSet? picker.endDate.toDate() : null;
                          setTueEnd2(selectedTime);
                        }}
                      >
                        <Form.Control
                          className="input-timepicker"
                          type="text"
                          name="endTime2"
                          onChange={() => {}}
                          disabled={isRestDayTue}
                          value={tueEnd2 ? moment(tueEnd2).format("hh:mm A") : ''}
                          id="tueEnd2"
                        />
                      </DateRangePicker>
                    </span>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          )}

          <Row className="gx-3">
            <Form.Label><strong>Wednesday</strong></Form.Label>
            <Col sm={5}>
              <Form.Group className="mb-3">
                <InputGroup>
                  <InputGroup.Text>
                    From
                  </InputGroup.Text>
                  <span className="input-affix-wrapper">
                    <span className="input-prefix">
                      <span className="feather-icon">
                        <Icons.Clock />
                      </span>
                    </span>
                    <DateRangePicker
                      initialSettings={{
                        singleDatePicker: true,
                        autoApply:true,
                        timePicker: true,
                        timePicker24Hour: false,
                        timePickerIncrement: 5,
                        locale: {
                          format: "hh:mm A",
                        },
                      }}
                      disabled={isRestDayWed}
                      onShow={hideCalender}
                      onApply={(event, picker) => {
                        const selectedTime = picker.startDate.toDate();
                        setWedStart(selectedTime);
                      }}
                      onHide={(event, picker) => {
                        const selectedTime = picker.startDate.toDate();
                        setWedStart(selectedTime);
                      }}
                    >
                      <Form.Control
                        className="input-timepicker"
                        type="text"
                        name="startTime"
                        onChange={() => {}}
                        disabled={isRestDayWed}
                        value={wedStart ? moment(wedStart).format("hh:mm A") : ''}
                        id="wedStart"
                      />
                    </DateRangePicker>
                  </span>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={5}>
              <Form.Group className="mb-3">
                <InputGroup>
                  <InputGroup.Text>
                    To
                  </InputGroup.Text>
                  <span className="input-affix-wrapper">
                    <span className="input-prefix">
                      <span className="feather-icon">
                        <Icons.Clock />
                      </span>
                    </span>
                    <DateRangePicker
                      initialSettings={{
                        singleDatePicker: true,
                        autoApply:true,
                        timePicker: true,
                        timePicker24Hour: false,
                        timePickerIncrement: 5,
                        locale: {
                          format: "hh:mm A",
                        },
                      }}
                      disabled={isRestDayWed}
                      onShow={hideCalender}
                      onApply={(event, picker) => {
                        const selectedTime = picker.endDate.toDate();
                        setWedEnd(selectedTime);
                      }}
                      onHide={(event, picker) => {
                        const selectedTime = picker.endDate.toDate();
                        setWedEnd(selectedTime);
                      }}
                    >
                      <Form.Control
                        className="input-timepicker"
                        type="text"
                        name="endTime"
                        onChange={() => {}}
                        disabled={isRestDayWed}
                        value={wedEnd ? moment(wedEnd).format("hh:mm A") : ''}
                        id="wedEnd"
                      />
                    </DateRangePicker>
                  </span>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={2} className="mt-1">
              <Form.Check
                type="checkbox"
                label="Rest Day"
                checked={isRestDayWed}
                onChange={(e) => setIsRestDayWed(e.target.checked ? 1 : 0)}
                id="restWed"
              />
            </Col>
          </Row>
          
          <input
            type="checkbox"
            style={{ transform: "scale(1.5)", marginRight: "10px" }}
            checked={isWed2TimeSet}
            onChange={(e) => {
              // If checkbox was previously checked and is now being unchecked
              if (isWed2TimeSet && !e.target.checked) {
                setWedStart2(null);
                setWedEnd2(null);
              }
              // Toggle checkbox state
              setWed2Time(!isWed2TimeSet);
            }}
          />
          <label> Tick to input another time range for Wednesday</label>
          {!isWed2TimeSet && <><br /><br /></>}
          {isWed2TimeSet && (
            <Row className="gx-3">
              <Col sm={5}>
                <Form.Group className="mb-3">
                  <InputGroup>
                    <InputGroup.Text>
                      From
                    </InputGroup.Text>
                    <span className="input-affix-wrapper">
                      <span className="input-prefix">
                        <span className="feather-icon">
                          <Icons.Clock />
                        </span>
                      </span>
                      <DateRangePicker
                        initialSettings={{
                          singleDatePicker: true,
                          autoApply:true,
                          timePicker: true,
                          timePicker24Hour: false,
                          timePickerIncrement: 5,
                          locale: {
                            format: "hh:mm A",
                          },
                        }}
                        disabled={isRestDayWed}
                        onShow={hideCalender}
                        onApply={(event, picker) => {
                          const selectedTime = isWed2TimeSet? picker.startDate.toDate() : null;
                          setWedStart2(selectedTime);
                        }}
                        onHide={(event, picker) => {
                          const selectedTime = isWed2TimeSet? picker.startDate.toDate() : null;
                          setWedStart2(selectedTime);
                        }}
                      >
                        <Form.Control
                          className="input-timepicker"
                          type="text"
                          name="startTime2"
                          onChange={() => {}}
                          disabled={isRestDayWed}
                          value={wedStart2 ? moment(wedStart2).format("hh:mm A") : ''}
                          id="wedStart2"
                        />
                      </DateRangePicker>
                    </span>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col sm={5}>
                <Form.Group className="mb-3">
                  <InputGroup>
                    <InputGroup.Text>
                      To
                    </InputGroup.Text>
                    <span className="input-affix-wrapper">
                      <span className="input-prefix">
                        <span className="feather-icon">
                          <Icons.Clock />
                        </span>
                      </span>
                      <DateRangePicker
                        initialSettings={{
                          singleDatePicker: true,
                          autoApply:true,
                          timePicker: true,
                          timePicker24Hour: false,
                          timePickerIncrement: 5,
                          locale: {
                            format: "hh:mm A",
                          },
                        }}
                        disabled={isRestDayWed}
                        onShow={hideCalender}
                        onApply={(event, picker) => {
                          const selectedTime = isWed2TimeSet? picker.endDate.toDate() : null;
                          setWedEnd2(selectedTime);
                        }}
                        onHide={(event, picker) => {
                          const selectedTime = isWed2TimeSet? picker.endDate.toDate() : null;
                          setWedEnd2(selectedTime);
                        }}
                      >
                        <Form.Control
                          className="input-timepicker"
                          type="text"
                          name="endTime2"
                          onChange={() => {}}
                          disabled={isRestDayWed}
                          value={wedEnd2 ? moment(wedEnd2).format("hh:mm A") : ''}
                          id="wedEnd2"
                        />
                      </DateRangePicker>
                    </span>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          )}

          <Row className="gx-3">
            <Form.Label><strong>Thursday</strong></Form.Label>
            <Col sm={5}>
              <Form.Group className="mb-3">
                <InputGroup>
                  <InputGroup.Text>
                    From
                  </InputGroup.Text>
                  <span className="input-affix-wrapper">
                    <span className="input-prefix">
                      <span className="feather-icon">
                        <Icons.Clock />
                      </span>
                    </span>
                    <DateRangePicker
                      initialSettings={{
                        singleDatePicker: true,
                        autoApply:true,
                        timePicker: true,
                        timePicker24Hour: false,
                        timePickerIncrement: 5,
                        locale: {
                          format: "hh:mm A",
                        },
                      }}
                      disabled={isRestDayThu}
                      onShow={hideCalender}
                      onApply={(event, picker) => {
                        const selectedTime = picker.startDate.toDate();
                        setThuStart(selectedTime);
                      }}
                      onHide={(event, picker) => {
                        const selectedTime = picker.startDate.toDate();
                        setThuStart(selectedTime);
                      }}
                    >
                      <Form.Control
                        className="input-timepicker"
                        type="text"
                        name="startTime"
                        onChange={() => {}}
                        disabled={isRestDayThu}
                        value={thuStart ? moment(thuStart).format("hh:mm A") : ''}
                        id="thuStart"
                      />
                    </DateRangePicker>
                  </span>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={5}>
              <Form.Group className="mb-3">
                <InputGroup>
                  <InputGroup.Text>
                    To
                  </InputGroup.Text>
                  <span className="input-affix-wrapper">
                    <span className="input-prefix">
                      <span className="feather-icon">
                        <Icons.Clock />
                      </span>
                    </span>
                    <DateRangePicker
                      initialSettings={{
                        singleDatePicker: true,
                        autoApply:true,
                        timePicker: true,
                        timePicker24Hour: false,
                        timePickerIncrement: 5,
                        locale: {
                          format: "hh:mm A",
                        },
                      }}
                      disabled={isRestDayThu}
                      onShow={hideCalender}
                      onApply={(event, picker) => {
                        const selectedTime = picker.endDate.toDate();
                        setThuEnd(selectedTime);
                      }}
                      onHide={(event, picker) => {
                        const selectedTime = picker.endDate.toDate();
                        setThuEnd(selectedTime);
                      }}
                    >
                      <Form.Control
                        className="input-timepicker"
                        type="text"
                        name="endTime"
                        onChange={() => {}}
                        disabled={isRestDayThu}
                        value={thuEnd ? moment(thuEnd).format("hh:mm A") : ''}
                        id="thuEnd"
                      />
                    </DateRangePicker>
                  </span>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={2} className="mt-1">
              <Form.Check
                type="checkbox"
                label="Rest Day"
                checked={isRestDayThu}
                onChange={(e) => setIsRestDayThu(e.target.checked ? 1 : 0)}
                id="restThu"
              />
            </Col>
          </Row>

          <input
            type="checkbox"
            style={{ transform: "scale(1.5)", marginRight: "10px" }}
            checked={isThu2TimeSet}
            onChange={(e) => {
              // If checkbox was previously checked and is now being unchecked
              if (isThu2TimeSet && !e.target.checked) {
                setThuStart2(null);
                setThuEnd2(null);
              }
              // Toggle checkbox state
              setThu2Time(!isThu2TimeSet);
            }}
          />
          <label> Tick to input another time range for Thursday</label>
          {!isThu2TimeSet && <><br /><br /></>}
          {isThu2TimeSet && (
            <Row className="gx-3">
              <Col sm={5}>
                <Form.Group className="mb-3">
                  <InputGroup>
                    <InputGroup.Text>
                      From
                    </InputGroup.Text>
                    <span className="input-affix-wrapper">
                      <span className="input-prefix">
                        <span className="feather-icon">
                          <Icons.Clock />
                        </span>
                      </span>
                      <DateRangePicker
                        initialSettings={{
                          singleDatePicker: true,
                          autoApply:true,
                          timePicker: true,
                          timePicker24Hour: false,
                          timePickerIncrement: 5,
                          locale: {
                            format: "hh:mm A",
                          },
                        }}
                        disabled={isRestDayThu}
                        onShow={hideCalender}
                        onApply={(event, picker) => {
                          const selectedTime = isThu2TimeSet? picker.startDate.toDate(): null;
                          setThuStart2(selectedTime);
                        }}
                        onHide={(event, picker) => {
                          const selectedTime = isThu2TimeSet? picker.startDate.toDate(): null;
                          setThuStart2(selectedTime);
                        }}
                      >
                        <Form.Control
                          className="input-timepicker"
                          type="text"
                          name="startTime2"
                          onChange={() => {}}
                          disabled={isRestDayThu}
                          value={thuStart2 ? moment(thuStart2).format("hh:mm A") : ''}
                          id="thuStart2"
                        />
                      </DateRangePicker>
                    </span>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col sm={5}>
                <Form.Group className="mb-3">
                  <InputGroup>
                    <InputGroup.Text>
                      To
                    </InputGroup.Text>
                    <span className="input-affix-wrapper">
                      <span className="input-prefix">
                        <span className="feather-icon">
                          <Icons.Clock />
                        </span>
                      </span>
                      <DateRangePicker
                        initialSettings={{
                          singleDatePicker: true,
                          autoApply:true,
                          timePicker: true,
                          timePicker24Hour: false,
                          timePickerIncrement: 5,
                          locale: {
                            format: "hh:mm A",
                          },
                        }}
                        disabled={isRestDayThu}
                        onShow={hideCalender}
                        onApply={(event, picker) => {
                          const selectedTime = isThu2TimeSet? picker.endDate.toDate() : null;
                          setThuEnd2(selectedTime);
                        }}
                        onHide={(event, picker) => {
                          const selectedTime = isThu2TimeSet? picker.endDate.toDate() : null;
                          setThuEnd2(selectedTime);
                        }}
                      >
                        <Form.Control
                          className="input-timepicker"
                          type="text"
                          name="endTime2"
                          onChange={() => {}}
                          disabled={isRestDayThu}
                          value={thuEnd2 ? moment(thuEnd2).format("hh:mm A") : ''}
                          id="thuEnd2"
                        />
                      </DateRangePicker>
                    </span>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          )}

          <Row className="gx-3">
            <Form.Label><strong>Friday</strong></Form.Label>
            <Col sm={5}>
              <Form.Group className="mb-3">
                <InputGroup>
                  <InputGroup.Text>
                    From
                  </InputGroup.Text>
                  <span className="input-affix-wrapper">
                    <span className="input-prefix">
                      <span className="feather-icon">
                        <Icons.Clock />
                      </span>
                    </span>
                    <DateRangePicker
                      initialSettings={{
                        singleDatePicker: true,
                        autoApply:true,
                        timePicker: true,
                        timePicker24Hour: false,
                        timePickerIncrement: 5,
                        locale: {
                          format: "hh:mm A",
                        },
                      }}
                      disabled={isRestDayFri}
                      onShow={hideCalender}
                      onApply={(event, picker) => {
                        const selectedTime = picker.startDate.toDate();
                        setFriStart(selectedTime);
                      }}
                      onHide={(event, picker) => {
                        const selectedTime = picker.startDate.toDate();
                        setFriStart(selectedTime);
                      }}
                    >
                      <Form.Control
                        className="input-timepicker"
                        type="text"
                        name="startTime"
                        onChange={() => {}}
                        disabled={isRestDayFri}
                        value={friStart ? moment(friStart).format("hh:mm A") : ''}
                        id="friStart"
                      />
                    </DateRangePicker>
                  </span>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={5}>
              <Form.Group className="mb-3">
                <InputGroup>
                  <InputGroup.Text>
                    To
                  </InputGroup.Text>
                  <span className="input-affix-wrapper">
                    <span className="input-prefix">
                      <span className="feather-icon">
                        <Icons.Clock />
                      </span>
                    </span>
                    <DateRangePicker
                      initialSettings={{
                        singleDatePicker: true,
                        autoApply:true,
                        timePicker: true,
                        timePicker24Hour: false,
                        timePickerIncrement: 5,
                        locale: {
                          format: "hh:mm A",
                        },
                      }}
                      disabled={isRestDayFri}
                      onShow={hideCalender}
                      onApply={(event, picker) => {
                        const selectedTime = picker.endDate.toDate();
                        setFriEnd(selectedTime);
                      }}
                      onHide={(event, picker) => {
                        const selectedTime = picker.endDate.toDate();
                        setFriEnd(selectedTime);
                      }}
                    >
                      <Form.Control
                        className="input-timepicker"
                        type="text"
                        name="endTime"
                        onChange={() => {}}
                        disabled={isRestDayFri}
                        value={friEnd ? moment(friEnd).format("hh:mm A") : ''}
                        id="friEnd"
                      />
                    </DateRangePicker>
                  </span>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={2} className="mt-1">
              <Form.Check
                type="checkbox"
                label="Rest Day"
                checked={isRestDayFri}
                onChange={(e) => setIsRestDayFri(e.target.checked ? 1 : 0)}
                id="restFri"
              />
            </Col>
          </Row>
          
          <input
            type="checkbox"
            style={{ transform: "scale(1.5)", marginRight: "10px" }}
            checked={isFri2TimeSet}
            onChange={(e) => {
              // If checkbox was previously checked and is now being unchecked
              if (isFri2TimeSet && !e.target.checked) {
                setFriStart2(null);
                setFriEnd2(null);
              }
              // Toggle checkbox state
              setFri2Time(!isFri2TimeSet);
            }}
          />
          <label> Tick to input another time range for Friday</label>
          {!isFri2TimeSet && <><br /><br /></>}
          {isFri2TimeSet && (
            <Row className="gx-3">
              <Col sm={5}>
                <Form.Group className="mb-3">
                  <InputGroup>
                    <InputGroup.Text>
                      From
                    </InputGroup.Text>
                    <span className="input-affix-wrapper">
                      <span className="input-prefix">
                        <span className="feather-icon">
                          <Icons.Clock />
                        </span>
                      </span>
                      <DateRangePicker
                        initialSettings={{
                          singleDatePicker: true,
                          autoApply:true,
                          timePicker: true,
                          timePicker24Hour: false,
                          timePickerIncrement: 5,
                          locale: {
                            format: "hh:mm A",
                          },
                        }}
                        disabled={isRestDayFri}
                        onShow={hideCalender}
                        onApply={(event, picker) => {
                          const selectedTime = isFri2TimeSet? picker.startDate.toDate() : null;
                          setFriStart2(selectedTime);
                        }}
                        onHide={(event, picker) => {
                          const selectedTime = isFri2TimeSet? picker.startDate.toDate() : null;
                          setFriStart2(selectedTime);
                        }}
                      >
                        <Form.Control
                          className="input-timepicker"
                          type="text"
                          name="startTime2"
                          onChange={() => {}}
                          disabled={isRestDayFri}
                          value={friStart2 ? moment(friStart2).format("hh:mm A") : ''}
                          id="friStart2"
                        />
                      </DateRangePicker>
                    </span>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col sm={5}>
                <Form.Group className="mb-3">
                  <InputGroup>
                    <InputGroup.Text>
                      To
                    </InputGroup.Text>
                    <span className="input-affix-wrapper">
                      <span className="input-prefix">
                        <span className="feather-icon">
                          <Icons.Clock />
                        </span>
                      </span>
                      <DateRangePicker
                        initialSettings={{
                          singleDatePicker: true,
                          autoApply:true,
                          timePicker: true,
                          timePicker24Hour: false,
                          timePickerIncrement: 5,
                          locale: {
                            format: "hh:mm A",
                          },
                        }}
                        disabled={isRestDayFri}
                        onShow={hideCalender}
                        onApply={(event, picker) => {
                          const selectedTime = isFri2TimeSet? picker.endDate.toDate() : null;
                          setFriEnd2(selectedTime);
                        }}
                        onHide={(event, picker) => {
                          const selectedTime = isFri2TimeSet? picker.endDate.toDate() : null;
                          setFriEnd2(selectedTime);
                        }}
                      >
                        <Form.Control
                          className="input-timepicker"
                          type="text"
                          name="endTime2"
                          onChange={() => {}}
                          disabled={isRestDayFri}
                          value={friEnd2 ? moment(friEnd2).format("hh:mm A") : ''}
                          id="friEnd2"
                        />
                      </DateRangePicker>
                    </span>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          )}

          <Row className="gx-3">
            <Form.Label><strong>Saturday</strong></Form.Label>
            <Col sm={5}>
              <Form.Group className="mb-3">
                <InputGroup>
                  <InputGroup.Text>
                    From
                  </InputGroup.Text>
                  <span className="input-affix-wrapper">
                    <span className="input-prefix">
                      <span className="feather-icon">
                        <Icons.Clock />
                      </span>
                    </span>
                    <DateRangePicker
                      initialSettings={{
                        singleDatePicker: true,
                        autoApply:true,
                        timePicker: true,
                        timePicker24Hour: false,
                        timePickerIncrement: 5,
                        locale: {
                          format: "hh:mm A",
                        },
                      }}
                      disabled={isRestDaySat}
                      onShow={hideCalender}
                      onApply={(event, picker) => {
                        const selectedTime = picker.startDate.toDate();
                        setSatStart(selectedTime);
                      }}
                      onHide={(event, picker) => {
                        const selectedTime = picker.startDate.toDate();
                        setSatStart(selectedTime);
                      }}
                    >
                      <Form.Control
                        className="input-timepicker"
                        type="text"
                        name="startTime"
                        onChange={() => {}}
                        disabled={isRestDaySat}
                        value={satStart ? moment(satStart).format("hh:mm A") : ''}
                        id="satStart"
                      />
                    </DateRangePicker>
                  </span>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={5}>
              <Form.Group className="mb-3">
                <InputGroup>
                  <InputGroup.Text>
                    To
                  </InputGroup.Text>
                  <span className="input-affix-wrapper">
                    <span className="input-prefix">
                      <span className="feather-icon">
                        <Icons.Clock />
                      </span>
                    </span>
                    <DateRangePicker
                      initialSettings={{
                        singleDatePicker: true,
                        autoApply:true,
                        timePicker: true,
                        timePicker24Hour: false,
                        timePickerIncrement: 5,
                        locale: {
                          format: "hh:mm A",
                        },
                      }}
                      disabled={isRestDaySat}
                      onShow={hideCalender}
                      onApply={(event, picker) => {
                        const selectedTime = picker.endDate.toDate();
                        setSatEnd(selectedTime);
                      }}
                      onHide={(event, picker) => {
                        const selectedTime = picker.endDate.toDate();
                        setSatEnd(selectedTime);
                      }}
                    >
                      <Form.Control
                        className="input-timepicker"
                        type="text"
                        name="endTime"
                        onChange={() => {}}
                        disabled={isRestDaySat}
                        value={satEnd ? moment(satEnd).format("hh:mm A") : ''}
                        id="satEnd"
                      />
                    </DateRangePicker>
                  </span>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={2} className="mt-1">
              <Form.Check
                type="checkbox"
                label="Rest Day"
                checked={isRestDaySat}
                onChange={(e) => setIsRestDaySat(e.target.checked ? 1 : 0)}
                id="restSat"
              />
            </Col>
          </Row>
          
          <input
            type="checkbox"
            style={{ transform: "scale(1.5)", marginRight: "10px" }}
            checked={isSat2TimeSet}
            onChange={(e) => {
              // If checkbox was previously checked and is now being unchecked
              if (isSat2TimeSet && !e.target.checked) {
                setSatStart2(null);
                setSatEnd2(null);
              }
              // Toggle checkbox state
              setSat2Time(!isSat2TimeSet);
            }}
          />
          <label> Tick to input another time range for Saturday</label>
          {!isSat2TimeSet && <><br /><br /></>}
          {isSat2TimeSet && (
            <Row className="gx-3">
              <Col sm={5}>
                <Form.Group className="mb-3">
                  <InputGroup>
                    <InputGroup.Text>
                      From
                    </InputGroup.Text>
                    <span className="input-affix-wrapper">
                      <span className="input-prefix">
                        <span className="feather-icon">
                          <Icons.Clock />
                        </span>
                      </span>
                      <DateRangePicker
                        initialSettings={{
                          singleDatePicker: true,
                          autoApply:true,
                          timePicker: true,
                          timePicker24Hour: false,
                          timePickerIncrement: 5,
                          locale: {
                            format: "hh:mm A",
                          },
                        }}
                        disabled={isRestDaySat}
                        onShow={hideCalender}
                        onApply={(event, picker) => {
                          const selectedTime = isSat2TimeSet? picker.startDate.toDate() : null;
                          setSatStart2(selectedTime);
                        }}
                        onHide={(event, picker) => {
                          const selectedTime = isSat2TimeSet? picker.startDate.toDate() : null;
                          setSatStart2(selectedTime);
                        }}
                      >
                        <Form.Control
                          className="input-timepicker"
                          type="text"
                          name="startTime2"
                          onChange={() => {}}
                          disabled={isRestDaySat}
                          value={satStart2 ? moment(satStart2).format("hh:mm A") : ''}
                          id="satStart2"
                        />
                      </DateRangePicker>
                    </span>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col sm={5}>
                <Form.Group className="mb-3">
                  <InputGroup>
                    <InputGroup.Text>
                      To
                    </InputGroup.Text>
                    <span className="input-affix-wrapper">
                      <span className="input-prefix">
                        <span className="feather-icon">
                          <Icons.Clock />
                        </span>
                      </span>
                      <DateRangePicker
                        initialSettings={{
                          singleDatePicker: true,
                          autoApply:true,
                          timePicker: true,
                          timePicker24Hour: false,
                          timePickerIncrement: 5,
                          locale: {
                            format: "hh:mm A",
                          },
                        }}
                        disabled={isRestDaySat}
                        onShow={hideCalender}
                        onApply={(event, picker) => {
                          const selectedTime = isSat2TimeSet? picker.endDate.toDate() : null;
                          setSatEnd2(selectedTime);
                        }}
                        onHide={(event, picker) => {
                          const selectedTime = isSat2TimeSet? picker.endDate.toDate() : null;
                          setSatEnd2(selectedTime);
                        }}
                      >
                        <Form.Control
                          className="input-timepicker"
                          type="text"
                          name="endTime2"
                          onChange={() => {}}
                          disabled={isRestDaySat}
                          value={satEnd2 ? moment(satEnd2).format("hh:mm A") : ''}
                          id="satEnd2"
                        />
                      </DateRangePicker>
                    </span>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          )}

          <Button
            type="submit"
            variant="primary"
            className="mt-5"
            disabled={isSubmitting}
            id="saveChangeBtn"
          >
            {isSubmitting ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                Saving Changes...
              </>
            ) : (
              " Save Changes"
            )}
          </Button>
        </Form>
      ) : (
        <div className="text-center py-30">
          <Spinner />
        </div>
      )}
      <ToastContainer
        position="top-right"
        style={{ top: "70px", right: "20px" }}
        enableMultiContainer
        containerId="toast-container"
      />
    </>
  );
};

export default RestaurantInfo;
