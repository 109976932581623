import React from "react";


const RestaurantCardsFilter = ({ item }) => {
  const tagColorMapping = {
    'halal': '#f4b084',
    'vegetarian': '#9fc5e8',
    'western': '#FFD900',
    'asian': '#E9DCC9',
    'french': '#A7C7E7',
    'indian': '#9FE2BF',
    'italian': '#FFFF8F',
    'indonesian': '#FFB6C1',
    'japanese': '#BDB5D5',
    'korean': '#D8BFD8',
    'mediterranean': '#E5AA70',
    'malaysian': '#FAA0A0',
    'mexican': '#B6D0E2',
    'thai': '#C1E1C1',
    'vietnamese': '#FAD5A5',
    'nyonya': '#E6E6FA'
  }
  // lookup for specific background color for each tag
  function getBackgroundColorForTag(tag) {
    return tagColorMapping[tag.toLowerCase()] || '#fae2b6'
  }

  const filteredSecondRowTags = item.tags && typeof item.tags === 'string' 
    ? item.tags.split(',')
      .filter(tag => ['vegetarian', 'halal'].includes(tag.trim().toLowerCase()))
    : [];

  const filteredThirdRowTags = item.tags && typeof item.tags === 'string' 
    ? item.tags.split(',')
      .filter(tag => !['vegetarian', 'halal', 'featured'].includes(tag.trim().toLowerCase()))
      .slice(0, 6)
    : [];

  return (
    <>
      <div className="restaurant-tags mt-1 mb-5 d-flex flex-column" style={{ gap: '5px', fontSize: '12px' }}>
        {/* First row for only 'featured' labels based on isBoarded field */}
        {item.isBoarded ? (
          <span 
            className="label fw-bold text-default" 
            style= {{
              background: '#FF7518',
              borderRadius: '16px',
              fontSize: '10px',
              fontFamily: 'Beanco',
              fontStyle: 'italic'
            }}
          > 
            {'#FEATURED'}
          </span>
        ) : (
          <div style={{ visibility: 'hidden' }}>&nbsp;</div>
        )}
        {/* Second row for only 'vegetarian' and/or 'halal' labels */}
        {filteredSecondRowTags.length > 0 ? (
          <div className="mb-1 d-flex flex-wrap" style={{ gap: '5px', fontSize: '12px', color: 'grey' }}>
            {filteredSecondRowTags.map((tag, index) => (
              <div 
                key={index} 
                className="fw-bold"
                style={{ 
                  background: getBackgroundColorForTag(tag.trim()), 
                  borderRadius: '16px', 
                  padding: '2px 6px', 
                  color: '#333', 
                  fontSize: '9px', 
                  fontStyle: 'italic'
                }}
              >
                {'#' + tag.trim().toUpperCase()}
              </div>
            ))}
          </div>
        ) : null}
        {/* Third row for the rest of the tags */}
        {filteredThirdRowTags.length > 0 ? (
          <div className="d-flex flex-wrap" style={{ gap: '5px', fontSize: '12px', color: 'grey' }}>
            {filteredThirdRowTags.map((tag, index) => (
              <div 
                key={index} 
                className="fw-bold"
                style={{ 
                  background: getBackgroundColorForTag(tag.trim()), 
                  borderRadius: '16px', 
                  padding: '2px 6px', 
                  color: '#333', 
                  fontSize: '9px', 
                  fontStyle: 'italic'
                }}
              >
                {'#' + tag.trim()}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default RestaurantCardsFilter;
