import React, { useState, useEffect } from "react";
import { Modal, Button, Carousel } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

import { useAuth } from "../../../services/authContext";
import useClientServices from "../../../services/clientService";
import RestaurantCardsBody from "./RestaurantCardsBody";
import LaunchCampaignBanner from "../LaunchCampaignBanner";
import welcomeImg from '../../../assets/developImg/WelcomeMessage.gif';


const RestaurantCards = () => {
  const { user } = useAuth();
  const [token, setToken] = useState(Cookies.get("token"));
  const [decodedToken, setDecodedToken] = useState(null);
  const { fetchClientInfo, clientInfo } = useClientServices();
  const [popUpdateProfile, setPopUpdateProfile] = useState(false);
  const history = useHistory();

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      setDecodedToken(decoded);
    }
  }, [token]);

  useEffect(() => {
    if (decodedToken) {
      if (user.role === "client") {
        fetchClientInfo(decodedToken.userId);
      }
    }
  }, [decodedToken]);

  useEffect(() => {
    if (clientInfo && (!clientInfo.clientName || !clientInfo.clientContact)) {
      setPopUpdateProfile(true);
    }
  }, [clientInfo]);

  const handleAgreeUpdateProfile = () => {
    history.push('/app/profile');
  };

  const isSmallScreen = window.innerWidth < 600;
  const isMediumScreen = window.innerWidth < 1024;

  const carouselImageStyle = {
    width: '100%',
    height: isSmallScreen ? '150px' : isMediumScreen ? '200px' : '250px',
    objectFit: 'contain',
    borderRadius: '15px'
  };

  return (
    <div className="hk-pg-body p-3 font-monospace">
      <Carousel className="mb-3" controls={false} indicators={false} interval={7000}>
        <Carousel.Item>
          <LaunchCampaignBanner />
        </Carousel.Item>
        <Carousel.Item>
          <img src={welcomeImg} alt='welcome' style={carouselImageStyle}/>
        </Carousel.Item>
      </Carousel>

      <RestaurantCardsBody clientInfo={clientInfo}/>

      <Modal show={popUpdateProfile} onHide={() => setPopUpdateProfile(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Welcome Foodie!</Modal.Title>
        </Modal.Header>
        <Modal.Body>You are almost there! Please complete updating your profile.</Modal.Body>
        <Modal.Footer>
          <Button id="yes" variant="primary" onClick={handleAgreeUpdateProfile}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RestaurantCards;
