import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  InputGroup,
  Form,
  Row,
  Button,
  Dropdown,
  CloseButton,
  Spinner
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import SimpleBar from "simplebar-react";
import * as Icons from "tabler-icons-react";

import defaultImage from "../../../assets/dist/img/default_photo.png";
import useRestaurantServices from "../../../services/restaurantService";
import RestaurantCardFooter from "./RestaurantCardsFooter";
import RestaurantCardFilter from "./RestaurantCardsFilter";
import Ratings from "../RatingsReviews/RestaurantsRating";


const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const RestaurantCardsBody = ({ clientInfo }) => {
  const history = useHistory();
  const { restaurantsData } = useRestaurantServices();
  const [newPhotosList, setNewPhotosList] = useState({});
  const [lists, setList] = useState([]);
  // Infinite Scroll
  const [restoredCurrentPage, setRestoredCurrentPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [allItemsLoaded, setAllItemsLoaded] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  //Search Variable
  const [searchTerm, setSearchTerm] = useState("");
  const [clickedLocation, setClickedLocation] = useState([]);
  const [clickedTags, setClickedTags] = useState([]);
  const [filteredList, setFilteredLists] = useState([...lists]);
  //Update the render to use filteredLists
  const [currentItems, setCurrentItems] = useState(filteredList.slice(0, currentPage * itemsPerPage));  
  //Sorting by name and rating
  const [sortByName, setSortByName] = useState(false);
  const [sortByRating, setSortByRating] = useState(false);
  //Set Loading animation
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    //GetItem from localStorage if there is any
    const getLocalStorageItem = (key, parse = false) => {
      const item = window.localStorage.getItem(key);
      return parse && item ? JSON.parse(item) : item;
    };

    setSearchTerm(getLocalStorageItem('searchTerm') || '');
    setClickedLocation(getLocalStorageItem('clickedLocation', true) || []);
    setClickedTags(getLocalStorageItem('clickedTags', true) || []);
    setSortByName(getLocalStorageItem('sortByName', true) || false);
    setSortByRating(getLocalStorageItem('sortByRating', true) || false);
    setRestoredCurrentPage(getLocalStorageItem('currentPage', true) || 1);

  }, []);

  useEffect(() => {
    //LocalStorage to store search, filter and sort history
    window.localStorage.setItem('searchTerm', searchTerm);
    window.localStorage.setItem('clickedLocation', JSON.stringify(clickedLocation));
    window.localStorage.setItem('clickedTags', JSON.stringify(clickedTags));
    window.localStorage.setItem('sortByName', sortByName);
    window.localStorage.setItem('sortByRating', sortByRating);
    window.localStorage.setItem('currentPage', currentPage);
  }, [searchTerm, clickedLocation, clickedTags, sortByName, sortByRating, currentPage]);

  const clearLocalStorage = () => {
    window.localStorage.removeItem('searchTerm');
    window.localStorage.removeItem('clickedLocation');
    window.localStorage.removeItem('clickedTags');
    window.localStorage.removeItem('sortByName');
    window.localStorage.removeItem('sortByRating');
    window.localStorage.removeItem('currentPage');

    setScrollTop(0);
  };

  let inactivityTimer;
  const resetTimer = () => {
    if (inactivityTimer) {
      clearTimeout(inactivityTimer);
    }
    inactivityTimer = setTimeout(() => {
      clearLocalStorage();
    }, 1200000); // 20 minutes in milliseconds
  };

  // Event listener for inactivity timer
  useEffect(() => {
    const events = ['mousemove', 'keydown', 'scroll', 'click'];

    const resetAndClearTimer = () => {
      resetTimer();
    };

    events.forEach(event => {
      window.addEventListener(event, resetAndClearTimer);
    });

    // Initialize the timer on component mount
    resetTimer();

    return () => {
      // Cleanup event listeners on component unmount
      events.forEach(event => {
        window.removeEventListener(event, resetAndClearTimer);
      });
      clearTimeout(inactivityTimer);
    };
  }, []);

  useEffect(() => {
    if (restaurantsData.length > 0) {
      // Append restaurant logo into first photoFiles array
      const photosList = {};
      restaurantsData.forEach((restaurant => {
        const photos = restaurant.restaurantLogo 
          ? [restaurant.restaurantLogo, ...restaurant.photoFiles] 
          : [defaultImage, ...restaurant.photoFiles];
        photosList[restaurant._id] = photos;
      }));
      setList(restaurantsData);
      setNewPhotosList(photosList);
    }
  }, [restaurantsData]);

  useEffect(() => {
    setLoading(true);
    //Returned Searched, Filtered and Sorted Restaurant Cards
    const filterItems = lists.filter((item) => {
      const matchesSearchTerm = searchTerm
        ? item.restaurantName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.restaurantAddress.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (item.tags && item.tags.length > 0 && item.tags.toLowerCase().includes(searchTerm.toLowerCase()))
        : true;

      const matchesLocation = clickedLocation.length > 0
        ? clickedLocation.some((selectedLocation) => 
          item.restaurantState && item.restaurantState.toLowerCase().includes(selectedLocation.toLowerCase())
        ) : true;
  
      const matchesClickedTags = clickedTags.length > 0
        ? clickedTags.some((selectedFilter) =>
          item.tags && item.tags.length > 0 && item.tags.toLowerCase().includes(selectedFilter.toLowerCase())
        ) : true;

      return matchesLocation && matchesSearchTerm && matchesClickedTags;
    });
  
    // Handles sorting by name 
    if (sortByName) {
      const sortedItemsByName = [...filterItems].sort((a, b) => a.restaurantName.localeCompare(b.restaurantName));
      setFilteredLists(sortedItemsByName);
    } 

    // Handles sorting by rating
    if (sortByRating) {
      const sortedItemsByRating = [...filterItems].sort((a, b) => b.ratings - a.ratings);
      setFilteredLists(sortedItemsByRating);
    }
    
    if (!sortByName && !sortByRating) {
      setFilteredLists([...filterItems]);
    }

    //Set the current page to the restored page if exists, else start from page 1
    if (restoredCurrentPage !== 1) {
      setCurrentPage(restoredCurrentPage);
    } else {
      setCurrentPage(1);
    }
  }, [lists, searchTerm, clickedLocation, clickedTags, sortByName, sortByRating]);

  useEffect(() => {
    // Infinite Scroll - auto Loading
    setCurrentItems(filteredList.slice(0, currentPage * itemsPerPage));
    if (currentItems.length !== 0 && currentItems.length >= filteredList.length && currentPage > 1) {
      setAllItemsLoaded(true);
    }

    setLoading(false);
  }, [currentPage, filteredList]);

  useEffect(() => {
    if (history.location.state && history.location.state.scrollTop) {
      // console.log('scrolling to', history.location.state.scrollTop);
      window.scrollTo({
        top: history.location.state.scrollTop,
        behavior: 'instant'
      });

      // Delay the reset of the scrollTop state
      setTimeout(() => {
        history.replace({
          ...history.location,
          state: {
            ...history.location.state,
            scrollTop: null
          }
        });
      }, 1000); // Delay in milliseconds (e.g., 1000ms = 1 second)
    }
  }, [history.location.state, currentItems]);

  useEffect(() => {
    // Infinite Scroll - auto Loading
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [allItemsLoaded]);

  const handleScroll = () => {
    // console.log('scroll position', document.documentElement.scrollTop);
    setScrollTop(document.documentElement.scrollTop);

    // Window Height + Scroll Top + 1 == ScrollHeight
    if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
      if (!allItemsLoaded) {
        setCurrentPage((prev) => prev + 1);
      }
    }
  };

  const handleLocationClick = (state) => {
    const updatedClickedLocation = clickedLocation.includes(state)
      ? clickedLocation.filter((t) => t !== state)
      : [...clickedLocation, state];
  
    setClickedLocation(updatedClickedLocation);
  };

  const handleTagClick = (tag) => {
    const updatedClickedTags = clickedTags.includes(tag)
      ? clickedTags.filter((t) => t !== tag)
      : [...clickedTags, tag];
  
    setClickedTags(updatedClickedTags);
  };

  const handleNameSort = () => {
    setSortByName(!sortByName);
    setSortByRating(false);
  };

  const handleRatingSort = () => {
    setSortByRating(!sortByRating);
    setSortByName(false);
  };

  const clearSearch = () => {
    setSearchTerm("");
  }

  const handleClickCounter = async (id) => {
    // Update clicks for each restaurant
    const timestamp = new Date();
    const username = clientInfo?.clientName || "Unknown";
    const userId = clientInfo?._id || "Unknown";
    const data = { timestamp, username, userId };

    await fetch(`${backendUri}/api/v0/public/restaurants/updateClicks/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    // Save scroll position to history state upon clicking
    history.replace({ ...history.location, state: { scrollTop }});
    history.push(`/restaurants/${id}`);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const locationState = [
    'Kuala Lumpur',
    'Selangor',
    'Penang',
    'Johor',
    'Perak',
    'Sarawak',
    'Sabah',
    'Pahang',
    'Negeri Sembilan',
    'Kedah',
    'Malacca',
    'Terengganu',
    'Kelantan',
    'Perlis',
    'Labuan',
  ]

  const tagColorMapping = {
    'halal': '#f4b084',
    'vegetarian': '#9fc5e8',
    'western': '#FFD900',
    'asian': '#E9DCC9',
    'french': '#A7C7E7',
    'indian': '#9FE2BF',
    'italian': '#FFFF8F',
    'indonesian': '#FFB6C1',
    'japanese': '#BDB5D5',
    'korean': '#D8BFD8',
    'mediterranean': '#E5AA70',
    'malaysian': '#FAA0A0',
    'mexican': '#B6D0E2',
    'thai': '#C1E1C1',
    'vietnamese': '#FAD5A5',
    'nyonya': '#E6E6FA'
  }

  return (
    <>
      <div className="contact-body" style={{ position: 'relative' }}>
        <div style={{ position: 'fixed', bottom: '50px', right: '15px', zIndex: '1' }}>
          <button 
            style={{
              border: 'none',
              borderRadius: '50%',
              padding: '5px 5px',
              cursor: 'pointer',
              fontSize: '10px',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
            }}
            onClick={scrollToTop}
          >
            <Icons.ArrowUpCircle size={20} color="#333333" />
          </button>
        </div>
        {restaurantsData.message === "No restaurants at the moment" ? (
          <div className="d-flex align-items-center justify-content-center vh-100">
            <h3>There is still no restaurant yet. Stay tuned!</h3>
          </div>
        ) : (
          <>
            <div className="sticky-container">
              <div className="d-flex flex-row justify-content-center align-items-center font-monospace mb-2 bg-light" style={{ marginLeft: '-15px', marginRight: '-24px', position: 'sticky', top: '80px', zIndex: 2 }}>
                <InputGroup className="me-1 shadow">
                  <InputGroup.Text className="feather-icon">
                    <Icons.Search/>
                  </InputGroup.Text>
                  <Form.Control
                    className="table-search custom-placeholder"
                    type="text"
                    placeholder="Search by Name | Tag"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ fontSize: '0.65rem', zIndex: 1 }}
                  />
                  {searchTerm && (
                    <CloseButton
                      variant="outline-primary"
                      onClick={clearSearch}
                      className="bg-transparent"
                      style={{
                        position: 'absolute',
                        right: '0.1rem',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        fontSize: '0.65rem',
                        padding: '0 0.5rem',
                        zIndex: 2
                      }}
                    />
                  )}
                </InputGroup>
                <Dropdown>
                  <Dropdown.Toggle className="btn btn-sm me-1 shadow" variant="outline-primary" 
                    style={{ 
                      display: "flex", 
                      alignItems: "center", 
                      padding: "0.2rem",
                      backgroundColor: clickedLocation.length > 0 ? '#FFAE42' : '#f0f0f0',
                    }}
                  >
                    <Icons.AdjustmentsHorizontal/>
                    <span className={clickedLocation.length > 0 ? 'text-black' : 'text-primary'} style={{ fontSize: "0.65rem" }}>States</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ width: '20rem' }}>
                    {locationState.map((state) => (
                      <Button
                        key={state}
                        style={{
                          backgroundColor: clickedLocation.includes(state) ? '#FFAE42' : '#f0f0f0',
                          borderRadius: '1rem', 
                          padding: '2px 6px', 
                          color: '#333', 
                          fontSize: '0.7rem', 
                          fontStyle: 'italic',
                          border: 'none',
                          outline: 'none',
                          margin: '0.1rem'
                        }}
                        onClick={() => handleLocationClick(state)}
                      >
                        {state.charAt(0).toUpperCase() + state.slice(1)}
                      </Button>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle className="btn btn-sm me-2 shadow" variant="outline-primary" 
                    style={{ 
                      display: "flex", 
                      alignItems: "center", 
                      padding: "0.2rem",
                      backgroundColor: clickedTags.length > 0 ? '#FFAE42' : '#f0f0f0',
                    }}
                  >
                    <Icons.AdjustmentsHorizontal/>
                    <span className={clickedTags.length > 0 ? 'text-black' : 'text-primary'} style={{ fontSize: '0.65rem' }}>Cuisine</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ width: '20rem' }}>
                    {Object.keys(tagColorMapping).map((tag) => (
                      <Button
                        key={tag}
                        style={{
                          backgroundColor: clickedTags.includes(tag) ? '#FFAE42' : '#f0f0f0',
                          borderRadius: '1rem', 
                          padding: '2px 6px', 
                          color: '#333', 
                          fontSize: '0.7rem', 
                          fontStyle: 'italic',
                          border: 'none',
                          outline: 'none',
                          margin: '0.1rem'
                        }}
                        onClick={() => handleTagClick(tag)}
                      >
                        {tag.charAt(0).toUpperCase() + tag.slice(1)}
                      </Button>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="d-flex flex-row justify-content-start align-items-center font-monospace" style={{ position: 'sticky', top: '120px', zIndex: 1 }}>
                <Button className="btn btn-sm rounded-0 bg-white fw-bold" style={{ fontSize: '0.7rem' }} disabled>Sort by:</Button>
                <Button 
                  className={`btn btn-sm btn-outline-primary text-muted border-primary rounded-0 ${sortByName ? 'bg-primary' : 'bg-white'}`}
                  style={{ fontSize: '0.65rem' }} 
                  onClick={() => handleNameSort()}
                >
                  Name
                </Button>
                <Button 
                  className={`btn btn-sm btn-outline-primary text-muted border-primary rounded-0 ${sortByRating ? 'bg-primary' : 'bg-white'}`}
                  style={{ fontSize: '0.65rem' }} 
                  onClick={() => handleRatingSort()}
                >
                  Ratings
                </Button>
              </div>
              <SimpleBar className="nicescroll-bar">
                {loading ? (
                  <div
                    className="d-flex justify-content-center align-items-center text-center"
                    style={{ height: "200px" }}
                  >
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      {"\u00A0\u00A0\u00A0"}Fetching Restaurants ...
                    </>
                  </div>
                ) : (
                  <>
                    {currentItems.length === 0 ? (
                      <div
                        className="d-flex justify-content-center align-items-center text-center"
                        style={{ height: "200px" }}
                      >
                        <>
                          No restaurants found
                        </>
                      </div>
                    ) : (
                      <>
                        <Row className="row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1 mt-2 gx-3">
                          {currentItems.map((item) => (
                            <Col key={item._id} className="mb-2">
                              <Card className="card-border contact-card shadow-sm" style={{ width: "100%", height: "94%" }}>
                                {item.restaurantPromo && (
                                  <div 
                                    className="promo-banner" 
                                    style={{
                                      position: 'absolute',
                                      top: 0,
                                      left: 0,
                                      background: 'linear-gradient(to right, #FF4D4F, #FF4D4F 50%, rgba(255, 117, 24, 0))',
                                      maskImage: 'linear-gradient(to right, black 80%, transparent)',
                                      color: 'white',
                                      padding: '5px 10px',
                                      transformOrigin: 'left top',
                                      fontSize: '11px',
                                      fontWeight: 'bold',
                                      textAlign: 'start',
                                      zIndex: 1,
                                      width: '50%',
                                    }}
                                  >
                                    <Icons.Discount2 size={20} className="me-1" />
                                    {item.restaurantPromo}
                                  </div>
                                )}
                                <Card.Body className="text-center" style={{ padding: '5px' }}>
                                  <div onClick={() => handleClickCounter(item._id)}>
                                    <div 
                                      className="photo-grid" 
                                      style={{ 
                                        display: 'flex', 
                                        overflowX: 'auto', 
                                        gap: '5px',
                                        justifyContent: newPhotosList[item._id]?.length === 1 ? 'center' : 'flex-start',
                                      }}
                                    >
                                      {newPhotosList[item._id]?.map((photo, index) => {
                                        return (
                                          <img
                                            key={index}
                                            className="img-fluid rounded-5"
                                            src={photo}
                                            alt="restaurant"
                                            style={{ width: "100px", height: "100px", objectFit: "cover", borderRadius: "15px" }}
                                          />
                                        );
                                      })}
                                    </div>
                                  </div>
                                  <div
                                    className="restaurant-name fw-bold"
                                    style={{ marginTop: "5px", fontSize: "13px" }}
                                  >
                                    <div onClick = {() => handleClickCounter(item._id)}>
                                      {item.restaurantName}
                                    </div>
                                  </div>
                                  <div
                                    className="restaurant-address fw-light mt-2"
                                    style={{ fontSize: "9px" }}
                                  >
                                    <div onClick = {() => handleClickCounter(item._id)}>
                                      {item.restaurantAddress}
                                    </div>
                                  </div>
                                  <RestaurantCardFilter item={item} />
                                  <div style={{ position: 'absolute', bottom: '10px', left: '10px', right: '10px', display: 'flex', justifyContent: 'space-between', width: 'calc(100% - 20px)' }}>
                                    <Ratings restaurantInfo={item} />
                                    <RestaurantCardFooter item={item} disableMoreDetails={true}/>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </>
                    )}
                  </>
                )}
              </SimpleBar>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RestaurantCardsBody;
